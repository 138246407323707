export enum TimeOptions {
  '15MIN' = '15Min',
  '1H' = '1H',
  '6H' = '6H',
  '12H' = '12H',
  '1D' = '1D',
  '1W' = '1W',
  '1M' = '1M',
  '1Y' = '1Y',
  '2Y' = '2Y',
  '5Y' = '5Y',
  '10Y' = '10Y',
}
