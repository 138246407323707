const EXPLORATION_PREFIX = 'Exploration';

const COPY = {
  O_DATA: `${EXPLORATION_PREFIX}.CopyODataQuery`,
};

const CLICK = {
  POWER_BI_CONNECTOR: `${EXPLORATION_PREFIX}.Click.PowerBiConnector`,
  GRAFANA_CONNECTOR: `${EXPLORATION_PREFIX}.Click.GrafanaConnector`,
  COLLAPSE_FULL_PAGE: `${EXPLORATION_PREFIX}.Click.CollapseFullPage`,
  EXPAND_FULL_PAGE: `${EXPLORATION_PREFIX}.Click.ExpandFullPage`,
  CLOSE_DETAILED_VIEW: `${EXPLORATION_PREFIX}.Click.CloseDetailedView`,
  CLOSE_FULL_PAGE: `${EXPLORATION_PREFIX}.Click.CloseFullPage`,
  RESET_FILTER: `${EXPLORATION_PREFIX}.Click.ResetFilters`,
  ALL_RESULTS: `${EXPLORATION_PREFIX}.Click.AllResults`,
  TOGGLE_ASSET_TABLE_VIEW: `${EXPLORATION_PREFIX}.Click.ToggleAssetTableView`,
  TOGGLE_FILTERS_VIEW: `${EXPLORATION_PREFIX}.Click.ToggleFilterView`,
};

const THREED_SELECT = {
  EMPHASIZE_CLICKABLE_OBJECT: `${EXPLORATION_PREFIX}.3D.EmphasizeClickableObject`,
  UNCLICKABLE_OBJECT: `${EXPLORATION_PREFIX}.3D.UnClickableObject`,
  FIT_TO_VIEW: `${EXPLORATION_PREFIX}.3D.FitToView`,
  SLICE: `${EXPLORATION_PREFIX}.3D.Slice`,
  MEASURING_TOOL: `${EXPLORATION_PREFIX}.3D.MeasuringTool`,
  NAVIGATION_HELP: `${EXPLORATION_PREFIX}.3D.NavigationHelp`,
  COPY_URL_TO_CLIPBOARD: `${EXPLORATION_PREFIX}.3D.CopyUrlToClipboard`,
};

const THREED_ACTION = {
  MODEL_SELECTED: `${EXPLORATION_PREFIX}.3D.ModelSelected`,
  SECONDARY_MODEL_SELECTED: `${EXPLORATION_PREFIX}.3D.SecondaryModelSelected`,
  IMAGE_360_SELECTED: `${EXPLORATION_PREFIX}.3D.360ImageSelected`,
  SECONDARY_IMAGE_360_SELECTED: `${EXPLORATION_PREFIX}.3D.Secondary360ImageSelected`,
  ASSET_LIST_SELECT: `${EXPLORATION_PREFIX}.3D.AssetSelectedFromList`,
  ASSET_PREVIEW_CLOSE: `${EXPLORATION_PREFIX}.3D.AssetPreviewClose`,
  ASSET_SEARCH: `${EXPLORATION_PREFIX}.3D.AssetSearch`,
  ASSET_SELECTED: `${EXPLORATION_PREFIX}.3D.AssetSelected`,
};

const SELECT = {
  FILTER_BY: `${EXPLORATION_PREFIX}.Select.FilterBy`,
  RELATIONSHIP_LABEL: `${EXPLORATION_PREFIX}.Select.RelationshipLabels`,
};

const SEARCH = {
  GLOBAL: `${EXPLORATION_PREFIX}.Search.Global`,
};

const LOAD = {
  APPLICATION: `${EXPLORATION_PREFIX}.Load.Application`,
};

export const EXPLORATION = {
  COPY,
  CLICK,
  SELECT,
  SEARCH,
  LOAD,
  THREED_ACTION,
  THREED_SELECT,
};
