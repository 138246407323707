import { Model3D } from '@cognite/sdk';

export const threeDModels: Model3D[] = [
  {
    name: 'Test Model 1',
    id: 10001,
    createdTime: new Date(1667388905139),
  },
  {
    name: 'Test Model 2',
    id: 10002,
    createdTime: new Date(1667388925767),
  },
];
